import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["sortable"];

  declare readonly sortableTarget: HTMLElement;

  connect() {
    const sortable = new Sortable(this.sortableTarget, {
      handle: ".draggable",
      animation: 150,
      ghostClass: "bg-light",
      onUpdate: function (evt) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append(
          "X-CSRF-Token",
          document
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content") || ""
        );

        const body = JSON.stringify({ list: sortable.toArray() });

        fetch("/survey/questionnaire_groups/update_order", {
          method: "PATCH",
          headers,
          body,
        });
      },
    });
  }
}
