/// <reference types="bootstrap" />
/// <reference types="@types/jquery" />
/// <reference types="flatpickr" />

declare global {
  interface JQuery {
    flatpickr(options: flatpickr.Options.Options): void;
  }
}

import { Controller } from "@hotwired/stimulus";
import { createApp, computed, ref, type App, onMounted } from "vue";
import { SWFQuestionnaire, type Questionnaire } from "@medware/survey-web-form";

import "@medware/survey-web-form/dist/style.css";
import type flatpickr from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da.js";

interface Messages {
  end: string;
  handleEnd: string;
}

export default class extends Controller {
  static values = {
    questionnaire: Object,
    messages: Object
  };

  // Stimulus clears the controller element when the controller is disconnected,
  // So when navigating back using history (SPA-mode) the controller is reconnected
  // but Vue has no template now. Hence we need a template to store the original template
  // and then mount the Vue app in an empty element.
  static targets = ["template", "vue"];

  declare readonly questionnaireValue: Questionnaire | undefined;
  declare readonly messagesValue: Messages | undefined;

  declare readonly templateTarget: HTMLTemplateElement;
  declare readonly vueTarget: HTMLElement;

  #app: App<Element> | undefined;

  connect() {
    this.#app = this.#createVue();
  }

  disconnect() {
    if (this.#app) {
      this.#app.unmount();
    }
  }

  #createVue() {
    const app = createApp({
      template: this.templateTarget.innerHTML,
      setup: () => {
        const controller = this;

        if (!controller.questionnaireValue) {
          window.alert("Kunne ikke finde spørgeskemaet");
          return;
        }

        const $form = ref<HTMLFormElement>();
        const $questionnaire = ref<InstanceType<typeof SWFQuestionnaire>>();
        const $inputJson = ref<HTMLInputElement>();
        const $inputCompletedAt = ref<HTMLInputElement>();
        const $inputHandle = ref<HTMLInputElement>();
        const $modal = ref<HTMLDivElement>();
        const $dateInput = ref<HTMLInputElement>();

        const bodyStyle = getComputedStyle(document.body);
        const styles = ref({
          primaryColor: bodyStyle.getPropertyValue("--bs-primary"),
          dangerColor: bodyStyle.getPropertyValue("--bs-danger"),
          sectionTextColor: bodyStyle.getPropertyValue("--bs-white")
        });

        onMounted(() => {
          if (!$dateInput.value) {
            return;
          }

          $($dateInput.value).flatpickr({
            locale: Danish,
            dateFormat: "d-m-Y",
            maxDate: "today"
          });
        });

        const questionnaire = ref(controller.questionnaireValue);

        const isInvalid = computed(() => $questionnaire.value?.status === "invalid");

        const isPartial = computed(() => $questionnaire.value?.status === "valid-partially-filled");

        const isLastPage = computed(
          () => $questionnaire.value?.isLastPage ?? false
        );

        const modalAction = ref<"save" | "saveAndHandle">("save");
        const buttonConfirmSendText = computed(() => (modalAction.value === "save" ? "Send" : "Send og håndter"));

        const buttonDismissText = ref("Fortryd");
        const modalConfirmText = ref("");

        function openConfirmModal(action: "save" | "saveAndHandle") {
          if (!$questionnaire.value || !$modal.value) {
            return;
          }

          modalAction.value = action;

          if (!isInvalid.value) {
            buttonDismissText.value = "Fortryd";

            const partialText = isPartial.value
              ? "Vær opmærksom på, at der er spørgsmål, som du ikke har besvaret."
              : "";

            const saveText = action === "save" ? controller.messagesValue?.end : controller.messagesValue?.handleEnd;

            modalConfirmText.value = [partialText, saveText].join(" ");
          } else {
            buttonDismissText.value = "OK";
            modalConfirmText.value = "Der er spørgsmål, som er krævet, men ikke udfyldt";
          }

          $($modal.value).modal("show");
        }

        function save() {
          if (
            !$questionnaire.value ||
            !$form.value ||
            !$inputJson.value ||
            !$inputCompletedAt.value ||
            !$inputHandle.value ||
            !$dateInput.value ||
            !questionnaire.value
          ) {
            return;
          }

          if (!$questionnaire.value.markCompleted()) {
            return;
          }

          $inputJson.value.value = JSON.stringify(questionnaire.value);
          $inputCompletedAt.value.value = $dateInput.value.value || "";

          if (modalAction.value === "saveAndHandle") {
            $inputHandle.value.value = "true";
          } else {
            $inputHandle.value.removeAttribute("name");
          }

          $form.value.submit();
        }

        function saveDraft() {
          if (!questionnaire.value || !$form.value || !$inputJson.value || !$inputCompletedAt.value) {
            return;
          }

          $inputJson.value.value = JSON.stringify(questionnaire.value);
          $inputCompletedAt.value.value = "";

          $form.value.submit();
        }

        return {
          // DOM refs (vue warning when $ prefixed is used)
          elForm: $form,
          elQuestionnaire: $questionnaire,
          elInputJson: $inputJson,
          elInputCompletedAt: $inputCompletedAt,
          elInputHandle: $inputHandle,
          elModal: $modal,
          elDateInput: $dateInput,

          // Data
          questionnaire,
          isInvalid,
          buttonConfirmSendText,
          buttonDismissText,
          modalConfirmText,
          styles,
          isLastPage,

          // Methods
          openConfirmModal,
          save,
          saveDraft
        };
      }
    });

    app.component("swf-questionnaire", SWFQuestionnaire);
    app.mount(this.vueTarget);

    return app;
  }
}
